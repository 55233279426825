@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Noto Sans TC;

    }
    body {
        @apply text-[#2e2e2e]
    }
    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
    }
    h1 {
        @apply text-[49px] leading-[60px] md:text-[70px] md:leading-[86px]

    }
    h2 {
        @apply text-[36px] leading-[54px] md:text-[52px] md:leading-[63px]
    }
    h3 {
        @apply text-[21px] leading-[27px] md:text-[30px] md:leading-[40px]
    }
    h5 {
        @apply text-[25px] leading-[33px] md:text-[36px] md:leading-[47px]
    }
    p {
        @apply text-[15px] leading-[20px] mb-[20px] md:text-[20px] md:leading-[25px]
    }
}

@layer components {
    footer {
        @apply text-[15px]


    }
    .section-container {
        @apply max-w-[1540px] mx-auto px-[25px] sm:px-6

    }
    .text-black {
        @apply text-[#2e2e2e]
    }
    .cta {
        @apply text-[14px] md:text-[18px] bg-theme-green text-white font-bold rounded-[25px]  hover:bg-theme-green-hover
    }
    .effect-cta {
        @apply inline-block bg-theme-color-4 text-white rounded-[35px] px-[30px] py-[20px] font-bold transition-all duration-500 hover:text-black hover:bg-theme-color-2 hover:-translate-y-[5px]
    }
    .effect-cta-2 {
        @apply inline-block bg-yellow-400 text-black rounded-[35px] px-[30px] py-[20px] font-bold transition-all duration-500 hover:text-theme-blue hover:bg-white hover:-translate-y-[5px]
    }
    .effect-cta-3 {
        @apply inline-block relative text-white tracking-[2px] text-[14px] font-bold rounded-[3px] bg-theme-orange p-[15px] transition-all
    }
    .gradient-white {
        background-image: linear-gradient(140deg,rgba(255, 255, 255, 0) 3%,rgba(255, 255, 255, 0.57) 23%,rgba(255, 255, 255, 0.88) 61%,rgba(255, 255, 255, 0) 99%);
        @apply absolute inset-0 h-full w-full
    }
}
